/**
 * 示例-自定义组件-分页表格
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-table 分页表格</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-table :columns="tableColumns" :data="tableData" :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage"></sp-table>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-table :columns="tableColumns" :data="tableData" :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage"&gt;&lt;/sp-table&gt;</pre>
          <p>分页表格的简单用法。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-table :columns="tableColumns" :data="tableData" border :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage"></sp-table>
          <Divider orientation="left" plain>带边框</Divider>
          <pre>&lt;sp-table :columns="tableColumns" :data="tableData" border :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage"&gt;&lt;/sp-table&gt;</pre>
          <p>设置属性 <span class="text-red">border</span>，可以添加表格的边框线。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-table :columns="selectionColumns" :data="tableData" :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage" @select="selectData"></sp-table>
          <Divider orientation="left" plain>可选择</Divider>
          <pre>&lt;sp-table :columns="tableColumns" :data="tableData" :total="tablePager.total" :page="tablePager.pageNum" :page-size="tablePager.pageSize" @change="changePage" @select="selectData"&gt;&lt;/sp-table&gt;</pre>
          <p>通过给 <span class="text-red">columns</span> 数据设置一项，指定 <span class="text-red">type: 'selection'</span>，即可自动开启多选功能。 </p>
          <p>给 data 项设置特殊 key <span class="text-red">_checked: true</span> 可以默认选中当前项。</p>
          <p>给 data 项设置特殊 key <span class="text-red">_disabled: true</span> 可以禁止选择当前项。</p>
          <p>正确使用好以下事件，可以达到需要的效果：</p>
          <p><span class="text-red">@select</span>，只要选中项发生变化时就会触发，返回值为 <span class="text-red">selection</span>，已选项。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
        <div class="page-main-content-subhead">Events</div>
        <Table :columns="eventsColumns" :data="eventsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'data': {
            tips: '显示的结构化数据',
            type: 'Array',
            default: '[]'
          },
          'columns': {
            tips: '表格列的配置描述',
            type: 'Array',
            default: '[]'
          },
          'stripe': {
            tips: '是否显示间隔斑马纹',
            type: 'Boolean',
            default: 'false'
          },
          'border': {
            tips: '是否显示纵向边框',
            type: 'Boolean',
            default: 'false'
          },
          'show-header': {
            tips: '是否显示表头',
            type: 'Boolean',
            default: 'true'
          },
          'height': {
            tips: '表格高度，单位 px，设置后，如果表格内容大于此值，会固定表头',
            type: 'Number, String',
            default: ''
          },
          'max-height': {
            tips: '表格最大高度，单位 px，设置后，如果表格内容大于此值，会固定表头',
            type: 'Number, String',
            default: ''
          },
          'loading': {
            tips: '表格是否加载中',
            type: 'Boolean',
            default: 'false'
          },
          'disabled-hover': {
            tips: '禁用鼠标悬停时的高亮',
            type: 'Boolean',
            default: 'false'
          },
          'size': {
            tips: '表格尺寸，可选值为 large、small、default 或者不填',
            type: 'String',
            default: ''
          },
          'no-data-text': {
            tips: '数据为空时显示的提示内容',
            type: 'String',
            default: '暂无数据'
          },
          'tooltip-theme': {
            tips: '列使用 tooltip 时，配置它的主题，可选值为 dark 或 light',
            type: 'String',
            default: 'dark'
          },
          'tooltip-max-width': {
            tips: '列使用 tooltip 时，配置 Tooltip 的最大宽，默认是 300',
            type: 'Number',
            default: '300'
          },
          'fixed-shadow': {
            tips: '列固定时，阴影显示规则，可选值为 auto、show、hide',
            type: 'String',
            default: 'auto'
          },
          'total': {
            tips: '数据总数',
            type: 'Number',
            default: '0'
          },
          'page': {
            tips: '当前页码',
            type: 'Number',
            default: '0'
          },
          'page-size': {
            tips: '每页条数',
            type: 'Number',
            default: '0'
          },
          'page-size-opts': {
            tips: '每页条数切换的配置',
            type: 'Array',
            default: '[10, 20, 50, 100]'
          },
          'simple': {
            tips: '简洁版',
            type: 'Boolean',
            default: 'false'
          },
          'show-total': {
            tips: '显示总数',
            type: 'Boolean',
            default: 'true'
          },
          'show-sizer': {
            tips: '显示分页，用来改变page-size',
            type: 'Boolean',
            default: 'true'
          },
          'show-elevator': {
            tips: '显示电梯，可以快速切换到某一页',
            type: 'Boolean',
            default: 'true'
          }
        },
        events: {
          'select': {
            tips: '在多选模式下有效，只要选中项发生变化时就会触发',
            return: 'selection：已选项数据'
          },
          'sort-change': {
            tips: '排序时有效，当点击排序时触发',
            return: 'column：当前列数据；key：排序依据的指标；order：排序的顺序，值为 asc 或 desc'
          },
          'change': {
            tips: '页码改变的回调，返回改变后的页码和每页条数',
            return: 'page：改变后的页码；pageSize：每页条数'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        eventsColumns: [
          {
            title: '事件名',
            key: 'name',
            width: 250
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '返回值',
            render: (h, params) => {
              let children = [];
              params.row.return.split('；').forEach(item => {
                let p = [];
                item.split('：').forEach((key, index) => {
                  p.push(h('span', {
                    attrs: {
                      class: index === 0 ? 'text-red' : ''
                    }
                  }, key));
                });
                if (p.length === 2) {
                  p.splice(1, 0, h('span', '：'));
                }
                children.push(h('p', p));
              });
              return h('div', children);
            }
          }
        ],
        tableColumns: [
          {
            title: '名称',
            key: 'name'
          },
          {
            title: '编码',
            key: 'code'
          },
          {
            title: '价格',
            key: 'price'
          },
          {
            title: '状态',
            key: 'status'
          }
        ],
        tableData: [],
        tablePager: {
          total: 0,
          pageNum: 1,
          pageSize: 10
        },
        eSelection: []
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      },
      eventsData() {
        let val = [];
        let data = this.events;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          val.push(item);
        });
        return val;
      },
      selectionColumns() {
        return [
          {
            type: 'selection',
            width: 60
          },
          ...this.tableColumns
        ];
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.test.queryTestList,
          mock: true
        }).then(data => {
          this.tableData = data.list || [];
          this.tablePager.total = data.total || 0;
        });
      },
      /**
       * 选中表格项
       */
      selectData(selection) {
        this.eSelection = selection;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.tablePager.pageNum = pager.page;
        this.tablePager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>